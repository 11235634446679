import React, { useState } from "react";
import { PostType } from "@/types";
import convertSlug from "@/utils/convertSlug";
import { Link } from "gatsby";
import { KeystoneImage } from "@/components/gatsby-image-keystone";
import moment from "moment";
import { FiLink } from "react-icons/fi";
import { useLocation } from "@reach/router";
import { CustomRenderer } from "@/utils/CustomRenderer";

interface IProps {
  data: PostType;
}

function NewsCart({ data }: IProps) {
  const { title, content, featureImage, profilePicture, topic, contentFull } =
    data;

  const a = topic?.map((x: any) => {
    return x.name;
  });

  const b = a.map((x: any) => {
    return (
      <p key={a.indexOf(x)} className="text-[12px]">
        {x}
      </p>
    );
  });

  const path = useLocation().href + `/${convertSlug(data.title)}`;

  const words = contentFull?.document
    .map((doc: any) => {
      return doc.children[0].text;
    })
    .join()
    .split(" ");

  function WPMCounter() {
    return Math.round(words?.length / 183);
  }

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleButtonClick = () => {
    setIsAlertVisible(true);

    setTimeout(() => {
      setIsAlertVisible(false);
    }, 1500);
  };

  return (
    <div>
      <div className="flex justify-between md:gap-[4rem] border-b-[1px] mb-[3rem]">
        <div className="w-full">
          {/* Profile & Date */}
          <div className="flex items-center align-middle [&>*]:mr-[10px]">
            {profilePicture && (
              <KeystoneImage
                baseUrl="steppelink"
                image={{
                  alt: profilePicture.id,
                  width: 20,
                  height: 20,
                  key: `${profilePicture.id}.${profilePicture.extension}`,
                }}
                layout="fixed"
                alt={profilePicture.id}
              />
            )}

            <div>
              <div className="text-[14px]">
                {data.writer} ·{" "}
                {moment(data.publishDate).startOf("day").fromNow()}
              </div>
            </div>
          </div>

          {/* Title */}
          <Link to={`/blogs/${convertSlug(title)}`}>
            <h4 className="mb-2 mt-3 md:text-[21px] font-[500] hover:underline md:leading-[24.5px] ">
              {title}
            </h4>
          </Link>

          {/* Sneak peek */}
          <div className="hidden overflow-hidden font-[300] md:block">
            <CustomRenderer document={content.document} />
          </div>

          <div className="flex justify-between items-center py-[10px] md:py-[35px] text-[12px]">
            <div className="flex items-center align-middle [&>*]:mr-2">
              <button className="rounded-3xl bg-white py-[4.5px] px-[7.5px] text-grayDark">
                {b}
              </button>
              <p className="inline-block text-white">·</p>
              <p className="text-[14px]">{WPMCounter()} min read</p>
            </div>
            <div className="[&>*]:mx-2 my-auto">
              <div
                className="relative group my-auto"
                onClick={handleButtonClick}
              >
                <button
                  aria-label="Copy link"
                  onClick={() => {
                    navigator.clipboard.writeText(path);
                  }}
                  className="active:translate-y-[1px] my-auto"
                >
                  <FiLink size={20} />
                </button>
                {isAlertVisible && (
                  <div className="absolute top-[100%] rounded-lg right-[50%] translate-x-[50%] border-[1px] dark:bg-white px-[10px]">
                    <p className="dark:text-black">Copied!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Feature photo */}
        <div className="my-auto sm:block hidden max-w-[200px] min-w-[200px] max-h-[200px]">
          <Link to={`/blogs/${convertSlug(title)}`}>
            {featureImage && (
              <KeystoneImage
                baseUrl="steppelink"
                image={{
                  alt: featureImage.id,
                  width: 200,
                  height: 200,
                  key: `${featureImage.id}.${featureImage.extension}`,
                }}
                layout="fullWidth"
                alt={featureImage.id}
              />
            )}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NewsCart;
