import React from "react";
import Layout from "@/components/Layout";
import { graphql } from "gatsby";
import useLocale from "@/hooks/useLocale";
import NewsCart from "@/components/News/NewsCart";
import RecentPosts from "@/components/News/RecentPosts";

const Blogs = ({ data }: any) => {
  const locale = useLocale();

  return (
    <Layout title="Blogs" ContactUsBtn={false}>
      <div className="lg:container px-[20px] xl:px-0">
        <div className="lg:grid-cols-[64%_36%] grid">
          {/* Posts */}
          <div data-aos="fade-right">
            {data.cms.posts.map(
              (post: any) =>
                post.localization.code === locale && (
                  <NewsCart key={post.id} data={post} />
                )
            )}
          </div>

          {/* Search recents */}
          <div data-aos="fade-left" className="lg:mt-0 mt-[4rem] lg:pl-[4rem]">
            <RecentPosts data={data.cms.posts} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query NewsPageQuery {
    cms {
      posts(
        where: { status: { equals: "published" } }
        orderBy: { publishDate: desc }
      ) {
        title
        status
        id
        publishDate
        localization {
          code
        }
        content {
          document(hydrateRelationships: true)
        }
        contentFull {
          document(hydrateRelationships: true)
        }
        featureImage {
          extension
          height
          id
          width
        }
        writer
        topic {
          name
        }
        profilePicture {
          width
          url
          id
          height
          filesize
          extension
        }
      }
    }
  }
`;

export default Blogs;
